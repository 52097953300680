import React, { useEffect, useState } from "react";
import Swiper from "swiper";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../redux/actions/cart";
import { setWishList } from "../../redux/actions/wishlist";

function ProductView(props) {
  const userinfo = useSelector((state) => state.userDetails.user);
  const cartData = useSelector((state) => state.cartList.cart);

  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userinfo.length === 0) {
      toast('You Need to Login First!!!')
    }
    else{
      try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("userId", userinfo.id);
      urlencoded.append("productId", props.id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}user/wishlist/addToWishlist`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            toast("Product Added to Wishlist");
            dispatch(setWishList(response.data));
          } else if (response.status === "error") {
            toast(response.message);
          } else {
            toast("Internal Server Error");
          }
        })
        .catch((error) => {
          console.error(error);
          toast("Internal Server Error");
        });
    } catch (error) {
      console.error(error);
      toast("Internal Server Error");
    }}
    
  };
  const [quantity, setQuantity] = useState(1);

  const increaseQuantity = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => prevQuantity - 1);
    }
  };
  const [swiper, setSwiper] = useState(null);
  const ProductNav = new Swiper(".single-product-nav-slider", {
    spaceBetween: 19,
    slidesPerView: 4,
    slidesPerGroup: 1,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    navigation: {
      nextEl: ".single-product-nav-slider .swiper-button-next",
      prevEl: ".single-product-nav-slider .swiper-button-prev",
    },
    breakpoints: {
      576: {
        slidesPerView: 4,
        spaceBetween: 19,
      },
      0: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
    },
  });

  useEffect(() => {
    const carouselSlider = new Swiper(".single-product-thumb-slider", {
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      thumbs: {
        swiper: ProductNav,
      },
      on: {
        init: function () {
          setSwiper(this);
        },
      },
    });

    return () => {
      carouselSlider.destroy();
    };
  }, []);

  const goToNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const goToPrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };
  const handleAddToCart = () => {
    if (props.quantity - props.sales === 0) {
      toast("Product is Out of Stock");
    } else {
      const cartItem = {
        id: props.id,
        title: props.title,
        price:
          props.discount === 0
            ? props.salePrice
            : props.salePrice - (props.salePrice * props.discount) / 100,
        colors: props.color,
        avgRating: props.avgRating,
        description: props.description,
        thumbnail: props.thumbnail,
      };

      const existingCartItem = cartData.find((item) => item.id === cartItem.id);
      if (existingCartItem) {
        existingCartItem.quantity += quantity;
        dispatch(setCart([...cartData]));
      } else {
        dispatch(setCart([...cartData, { ...cartItem, quantity }]));
      }
      toast("Product added to cart");
    }
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="product-single-item" data-margin-bottom="63">
          <div className="row">
            <div className="col-lg-6">
              <div className="product-thumb">
                <div className="swiper-container single-product-thumb-slider">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="zoom">
                        <a
                          class="lightbox-image w-100"
                          data-fancybox="gallery"
                          style={{width: 400,height: 700}}
                          href={`https://admin.zarkofficial.com/products/${props.thumbnail}`}
                        >
                          <img
                            src={`https://admin.zarkofficial.com/products/${props.thumbnail}`}
                            alt="thumbnail"
                            className="w-100 rounded"
                            width={400}
                            height={700}
                            loading="lazy"
                          />
                        </a>
                        {props.quantity - props.sales === 0 ? (
                          <span className="sale-title sticker">SoldOut</span>
                        ) : (
                          ""
                        )}
                        {props.discount === 0 ? (
                          ""
                        ) : props.quantity - props.sales > 0 ? (
                          <span className="sale-title sticker">Sale</span>
                        ) : (
                          ""
                        )}
                        {props.discount === 0 ? (
                          ""
                        ) : props.quantity - props.sales > 0 ? (
                          <span className="sale-title sticker">Sale</span>
                        ) : (
                          ""
                        )}

                        {props.discount === 0 ? (
                          ""
                        ) : props.quantity - props.sales > 0 ? (
                          <span className="percent-count sticker">
                            -{props.discount}%
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {props.images &&
                      props.images.map((image, index) => (
                        <div className="swiper-slide">
                          <div className="zoom">
                            <a
                              class="lightbox-image w-100"
                              data-fancybox="gallery"
                              href={`https://admin.zarkofficial.com/products/${image}`}
                              style={{width: 400,height: 700}}
                            >
                              <img
                                src={`https://admin.zarkofficial.com/products/${image}`}
                                alt={`product${index}`}
                                className="w-100 rounded"
                                width={400}
                                height={700}
                                loading="lazy"
                              />
                            </a>
                            {props.quantity - props.sales === 0 ? (
                              <span className="sale-title sticker">
                                SoldOut
                              </span>
                            ) : (
                              ""
                            )}
                            {props.discount === 0 ? (
                              ""
                            ) : props.quantity - props.sales > 0 ? (
                              <span className="sale-title sticker">Sale</span>
                            ) : (
                              ""
                            )}
                            {props.discount === 0 ? (
                              ""
                            ) : props.quantity - props.sales > 0 ? (
                              <span className="sale-title sticker">Sale</span>
                            ) : (
                              ""
                            )}

                            {props.discount === 0 ? (
                              ""
                            ) : props.quantity - props.sales > 0 ? (
                              <span className="percent-count sticker">
                                -{props.discount}%
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className="swiper-container single-product-nav-slider product-nav">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <img
                      src={`https://admin.zarkofficial.com/products/${props.thumbnail}`}
                      alt="thumbnail"
                      className="w-100 rounded"
                      height={150}
                      loading="lazy"
                    />
                  </div>
                  {props.images &&
                    props.images.map((image, index) => (
                      <div className="swiper-slide">
                        <img
                          src={`https://admin.zarkofficial.com/products/${image}`}
                          alt={`product${index}`}
                          className="w-100 rounded"
                          height={150}
                          loading="lazy"
                        />
                      </div>
                    ))}
                </div>
                <div className="swiper-button-prev" onClick={goToPrev}>
                  <i className="ei ei-icon_arrow_carrot-left"></i>
                </div>
                <div className="swiper-button-next" onClick={goToNext}>
                  <i className="ei ei-icon_arrow_carrot-right"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="product-single-info">
                <h4 className="title">{props.title}</h4>
                <div className="prices">
                  {props.discount === 0 ? (
                    <span className="price">Rs.{props.salePrice}</span>
                  ) : (
                    <span className="price">
                      Rs.
                      {props.salePrice -
                        (props.salePrice * props.discount) / 100}
                    </span>
                  )}
                  {props.discount === 0 ? (
                    ""
                  ) : (
                    <span className="price-old">Rs.{props.salePrice}</span>
                  )}
                </div>
                <div className="star-content">
                  {Array.from({ length: 5 }).map((_, index) => (
                    <i
                      key={index}
                      className={
                        index < props.avgRating ? "fa fa-star" : "fa fa-star-o"
                      }
                    ></i>
                  ))}
                </div>
                <p>{props.description}</p>
                <div className="product-select-action">
                  <div className="select-item">
                    <label htmlFor="productColorSelect">Color</label>
                    <select
                      className="form-select"
                      id="productColorSelect"
                      aria-label="Color select example"
                    >
                      {props.colors ? (
                        JSON.parse(props.colors).map((color, index) => (
                          <option key={index} value={color}>
                            {color}
                          </option>
                        ))
                      ) : (
                        <option value="">No colors available</option>
                      )}
                    </select>
                  </div>
                </div>
                <div className="product-action-simple">
                  <div className="product-quick-action row">
                    <div className="product-quick-qty col-md-4">
                      <div className="pro-qty">
                        <div className="d-flex flex-row align-items-center">
                          <button
                            className="btn btn-light"
                            onClick={decreaseQuantity}
                            type="button"
                          >
                            -
                          </button>
                          <input
                            type="text"
                            className="form-control text-center"
                            title="Quantity"
                            value={quantity}
                            readOnly
                          />
                          <button
                            className="btn btn-light"
                            onClick={increaseQuantity}
                            type="button"
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt-3 mt-lg-0">
                      {props.quantity - props.sales === 0 ? (
                        <button className="btn btn-dark" disabled>
                          Add to cart
                        </button>
                      ) : (
                        <button
                          className="btn btn-dark ms-auto"
                          onClick={handleAddToCart}
                        >
                          Add to cart
                        </button>
                      )}

                      <button
                        className="btn btn-outline-dark ms-2"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Add to WishList
                      </button>
                    </div>
                  </div>

                  <div className="social-sharing">
                    <span>Share:</span>
                    <div className="social-icons">
                      <a
                        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                          `https://zarkofficial.com/`
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                      <a
                        href="https://www.instagram.com/zark_official123/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i className="fa fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductView;
