import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import BreadCrumb from "../Components/BreadCrumb/BreadCrumb";
import Dashboard from "../Components/Accounts/Dashboard";
import RecentOrders from "../Components/Accounts/RecentOrders";
import AccountDetails from "../Components/Accounts/AccountDetails";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/actions/userProfile";

function Account() {
  const userData = useSelector((state) => state.userDetails.user);
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("id")) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/order/getByEmail/${userData.email}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setOrders(result.data);
        } else {
          console.log("Error:", result.error);
        }
      })
      .catch((error) => console.log("Error:", error));
  }, []);
  const totalOrders = orders.length;
  const handleLogout = () => {
    localStorage.removeItem("id");
    navigate("/login");
    window.location.reload();
    dispatch(setUser([]));
  };

  return (
    <>
      <Navigation />
      <BreadCrumb title="My Account" name="Account" />
      <section className="container py-5">
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="card">
              <div className="card-body">
                <div
                  className="nav flex-column nav-pills me-3"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <button
                    className="nav-link active text-black bg-light my-1 active"
                    id="v-pills-home-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-home"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-home"
                    aria-selected="true"
                  >
                    Dashboard
                  </button>
                  <button
                    className="nav-link text-black bg-light my-1"
                    id="v-pills-profile-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-profile"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-profile"
                    aria-selected="false"
                  >
                    Recent Orders
                  </button>
                
                  <button
                    className="nav-link text-black bg-light my-1"
                    id="v-pills-messages-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-messages"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-messages"
                    aria-selected="false"
                  >
                    Account Details
                  </button>
                  <Link
                    className="nav-link text-black bg-light my-1 text-center"
                    onClick={handleLogout}
                  >
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-12 mt-2 md-md-0">
            <div className="card">
              <div className="card-body">
                <div className="tab-content" id="v-pills-tabContent">
                  <Dashboard totalOrders={totalOrders} />
                  <RecentOrders orders={orders} />
                  <AccountDetails />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default Account;
