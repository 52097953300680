import React from "react";
import { Link } from "react-router-dom";
import { UilExclamationTriangle } from "@iconscout/react-unicons";

function Error() {
  return (
    <div
      className="text-center text-white d-flex flex-column justify-content-center align-items-center"
      style={{
        minHeight: "100vh",
        fontFamily: "Verdana, Geneva, Tahoma, sans-serif",
      }}
    >
      <div className="container my-auto">
        <h1 className="display-1 d-flex justify-content-center">
          <UilExclamationTriangle size="86" className="text-red" />
          <strong className="ms-2 fw-bold text-pink">404</strong>
        </h1>
        <h2 className="display-6 fw-bolder text-black mt-3">
          Oops.. You just found an error page..
        </h2>
        <h4 className="text-secondary mt-3">
          We are sorry but the page you are looking for was not found...
        </h4>
        <Link className="btn btn-block btn-pink mt-2" to="/">
          <p className="m-auto text-white mt-2">Home</p>
        </Link>
      </div>
    </div>
  );
}

export default Error;
