import React, { useState } from "react";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import BreadCrumb from "../Components/BreadCrumb/BreadCrumb";
import Spinner from "../Components/Loader/Spinner";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Forget() {
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setSpinner(true);

    // Validations
    if (credentials.email.length === 0) {
      toast("Please enter Email");
      setSpinner(false);
      return;
    }
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("email", credentials.email);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}user/user/resetPassword`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            toast(response.message);
            setSpinner(false);
            setCredentials({
              email: "",
            });
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          } else if (response.status === "error") {
            toast(response.message);
            setSpinner(false);
            setCredentials({
              email: "",
            });
          } else {
            toast("Internal Server Error");
            setSpinner(false);
          }
        })
        .catch((error) => {
          console.error(error);
          toast("Internal Server Error");
          setSpinner(false);
        });
    } catch (error) {
      console.error(error);
      toast("Internal Server Error");
      setSpinner(false);
    }
  };

  return (
    <>
      <Navigation />
      <BreadCrumb title="Recover Account" name="Forget" />
      <main className="main-content">
        <section className="account-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-8 m-auto">
                <div className="account-form-wrap">
                  <div className="login-form">
                    <div className="content">
                      <h4 className="title mb-5">Forget Password?</h4>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="email"
                              placeholder="Email"
                              onChange={onChange}
                              value={credentials.email}
                              name="email"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="login-form-group">
                            <button
                              className="btn btn-block btn-pink btn-lg rounded-5 text-white"
                              type="submit"
                            >
                              {spinner === false ? "Submit" : <Spinner />}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <ToastContainer />
    </>
  );
}

export default Forget;
