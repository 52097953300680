import React, { useEffect } from "react";
import PreLoader from "../Components/Loader/PreLoader";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import BreadCrumb from "../Components/BreadCrumb/BreadCrumb";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <PreLoader />
      <Navigation />
      <BreadCrumb title="About" name="About" />
      <main className="main-content">
        <section className="about-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="about-thumb">
                  <Link to="#/">
                    <img
                      className="w-100 rounded"
                      src="assets/images/about/1.jpg"
                      alt="HasTech"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-content">
                  <h4 className="title">Our company</h4>
                  <p>
                  Zark Official, synonymous with style since its establishment. 
                  Weaving casual fancy and kids' fashion with a touch of elegance, 
                  our heritage is rooted in the art of crafting ready-made clothes since 2010. 
                  For over 14 years, we've honed our skills, creating clothing that blends comfort and fashion effortlessly. 
                  Quality is our hallmark, as we meticulously select premium materials for our ladies' 
                  ready-made garments, ensuring each piece embodies softness and joy. 
                  Our skilled artisans handcraft each item, upholding the highest standards of craftsmanship.
                  </p>
                  <p>
                  Our diverse collection caters to all occasions and ages, 
                  offering chic options for ladies, trendy casual wear, 
                  and adorable outfits for kids. We're committed to sustainability, 
                  incorporating eco-friendly materials and ethical manufacturing practices. 
                  At Zark Official, we strive to exceed customer expectations, providing an elegant and memorable shopping experience. 
                  Join us in celebrating the tradition of Zark Official, where fashion meets quality, and every purchase contributes to a brighter future. 
                  Explore our collection today and embrace the essence of timeless style.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="team-area">
                    <div className="container pt-2">
                        <div className="row">
                            <div className="col-lg-12 m-auto">
                                <div className="section-title section-title-style-two text-center"  >
                                    <h2 className="sub-title">HAND WORK</h2>
                                    <h2 className="title">our expert team</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="team-item">
                                    <div className="inner-content">
                                        <div className="thumb">
                                            <Link to="#/"><img className="w-100" src="assets/images/team/1.jpg" alt="HasTech" /></Link>
                                        </div>
                                        <div className="content">
                                            <h4 className="title"><Link to="#/">Marcos Alonso</Link></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="team-item">
                                    <div className="inner-content">
                                        <div className="thumb">
                                            <Link to="#/"><img className="w-100" src="assets/images/team/2.jpg" alt="HasTech" /></Link>
                                        </div>
                                        <div className="content">
                                            <h4 className="title"><Link to="#/">Farhan</Link></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6 col-md-4 col-lg-4">
                                <div className="team-item">
                                    <div className="inner-content">
                                        <div className="thumb">
                                            <Link to="#/"><img className="w-100" src="assets/images/team/3.jpg" alt="HasTech" /></Link>
                                        </div>
                                        <div className="content">
                                            <h4 className="title"><Link to="#/">Marcos Alonso</Link></h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
      </main>
      <Footer />
    </div>
  );
}

export default About;
