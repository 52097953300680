import React, { useState } from "react";
import { Link } from "react-router-dom";
import { UilHeart, UilUser } from "@iconscout/react-unicons";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../redux/actions/cart";
import $ from "jquery";

function Navigation() {
  const dispatch = useDispatch();
  const isLoggedIn = localStorage.getItem("id");
  const cartData = useSelector((state) => state.cartList.cart);

  const handleDeleteCartItem = (itemId) => {
    const updatedCartData = cartData.filter((item) => item.id !== itemId);
    dispatch(setCart(updatedCartData));
  };

  let cartTotal = 0;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = () => {
    setIsMenuOpen(true);
    $("body").addClass("fix");
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    $("body").removeClass("fix");
  };

  const allCategory = useSelector((state) => state.allCategories.allcategories);
  let menCatId = null;
  let womenCatId = null;

  const findCategoryIdByName = (categoryName) => {
    const category = allCategory?.find(
      (category) => category.name === categoryName
    );
    return category ? category.id : null;
  };

  menCatId = findCategoryIdByName("Men Shawl");
  womenCatId = findCategoryIdByName("Women Shawl");

  return (
    <div>
      <header className="header-area header-default sticky-header">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 col-sm-4 col-lg-3">
              <div className="header-logo-area">
                <Link to="/" className="logoBox">
                  <img
                  style={{height:"62px"}}
                    className="logo-main"
                    src="assets/images/logo.png"
                    width={"30%"}
                    alt="Logo"
                  />
                  <div className="text-black fw-bold tagLine">Style That Speaks, Outfits That Inspire</div>
                </Link>
              </div>
            </div>
            <div className="col-sm-4 col-lg-7 d-none d-lg-block">
              <div className="header-navigation-area">
                <ul className="main-menu nav position-relative">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/products">Products</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-7 col-lg-2 d-none d-sm-block text-end">
              <div className="header-action-area">
                <ul className="header-action">
                  <li className="search-item ms-1">
                    {isLoggedIn ? (
                      <Link className="action-item" to="/account">
                        <UilUser className="mb-1" size="26" />
                      </Link>
                    ) : (
                      <Link className="action-item" to="/login">
                        <UilUser className="mb-1" size="26" />
                      </Link>
                    )}
                  </li>
                  <li className="search-item ms-1">
                    <Link className="action-item" to="/wishlist">
                      <UilHeart className="mb-1" size="26" />
                    </Link>
                  </li>
                  <li className="mini-cart ms-1">
                    <Link className="action-item" to="/cart">
                      <i className="zmdi zmdi-shopping-cart-plus icon"></i>
                      <span className="cart-quantity">{cartData.length}</span>
                    </Link>
                    <div className="mini-cart-dropdown">
                      {cartData.length === 0 ? (
                        <p className="cart-empty text-center bg-light p-3">
                          <span className="text-muted fs-5">Cart is empty</span>
                        </p>
                      ) : (
                        <>
                          {cartData?.map((item, index) => {
                            const itemTotal = item?.price * item?.quantity;
                            cartTotal += itemTotal;
                            return (
                              <div className="cart-item" key={index}>
                                <div className="thumb">
                                  <img
                                    className="w-100"
                                    src={`https://admin.zarkofficial.com/products/${item?.thumbnail}`}
                                    alt={item?.title}
                                  />
                                </div>
                                <div className="content">
                                  <h5 className="title">
                                    <Link to="/">{item?.title}</Link>
                                  </h5>
                                  <span className="product-quantity">
                                    {item?.quantity} × Rs.{" "}
                                    {item?.price?.toFixed(2)}
                                  </span>
                                  <button
                                    className="cart-trash btn"
                                    onClick={() =>
                                      handleDeleteCartItem(item.id)
                                    }
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                          <div className="cart-total-money">
                            <h5>
                              Total:{" "}
                              <span className="money">
                                Rs.{cartTotal?.toFixed(2)}
                              </span>
                            </h5>
                          </div>
                          <div className="cart-btn">
                            <Link className="rounded" to="/cart">
                              View Cart
                            </Link>
                            <Link className="rounded" to="/checkout">
                              Checkout
                            </Link>
                          </div>
                        </>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-sm-1 d-block d-lg-none text-end">
              <div className="header-action-area">
                <ul className="header-action">
                  <li className="search-item ms-1">
                    {isLoggedIn ? (
                      <Link className="action-item" to="/account">
                        <UilUser className="mb-1" size="26" />
                      </Link>
                    ) : (
                      <Link className="action-item" to="/login">
                        <UilUser className="mb-1" size="26" />
                      </Link>
                    )}
                  </li>
                  <li className="search-item ms-1">
                    <Link className="action-item" to="/wishlist">
                      <UilHeart className="mb-1" size="26" />
                    </Link>
                  </li>
                  <li className="mini-cart ms-1">
                    <Link className="action-item" to="/cart">
                      <i className="zmdi zmdi-shopping-cart-plus icon"></i>
                      <span className="cart-quantity">{cartData.length}</span>
                    </Link>
                  </li>
                  <button
                    className="btn-menu ms-3"
                    onClick={openMenu}
                    type="button"
                  >
                    <i className="zmdi zmdi-menu"></i>
                  </button>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <aside className={`off-canvas-wrapper ${isMenuOpen ? "active" : ""}`}>
        <div className="off-canvas-inner">
          <div className="off-canvas-overlay" onClick={closeMenu}></div>
          <div className="off-canvas-content">
            <div className="off-canvas-header">
              <div className="close-action" onClick={closeMenu}>
                <button className="btn-menu-close">
                  menu <i className="fa fa-chevron-left"></i>
                </button>
              </div>
            </div>
            <div className="off-canvas-item">
              <div className="res-mobile-menu menu-active-one">
                <div className="slicknav_menu">
                  <ul className="slicknav_nav slicknav_hidden">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/products">All Products</Link>
                    </li>
                    {/* <li>
                      <Link to={`/products/${menCatId}`}>Mens Shawl</Link>
                    </li>
                    <li>
                      <Link to={`/products/${womenCatId}`}>Women Shawl</Link>
                    </li> */}
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/cart">Cart ({cartData?.length})</Link>
                    </li>
                    <li>
                      <Link to="/wishlist">Wishlist</Link>
                    </li>
                    <li>
                      {isLoggedIn ? (
                        <Link to="/account">Account</Link>
                      ) : (
                        <Link to="/login">Login</Link>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
}

export default Navigation;
