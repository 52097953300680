import React, { useState } from "react";
import { Link } from "react-router-dom";
import { UilFacebook } from "@iconscout/react-unicons";
import { UilInstagram } from "@iconscout/react-unicons";
import Spinner from "./Loader/Spinner";
import { ToastContainer, toast } from "react-toastify";

function Footer() {
  const [spinner, setSpinner] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSpinner(true);

    // Validations
    if (credentials.email.length === 0) {
      toast("Please enter Email");
      setSpinner(false);
      return;
    }
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("email", credentials.email);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}user/newsletter/addRecord`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            toast("Subscribed");
            setSpinner(false);
            setCredentials({
              email: "",
            });
          } else if (response.status === "error") {
            toast(response.message);
            setSpinner(false);
            setCredentials({
              email: "",
            });
          } else {
            toast("Internal Server Error");
            setSpinner(false);
          }
        })
        .catch((error) => {
          console.error(error);
          toast("Internal Server Error");
          setSpinner(false);
        });
    } catch (error) {
      console.error(error);
      toast("Internal Server Error");
      setSpinner(false);
    }
  };

  return (
    <div>
      <footer className="footer-area">
        <div className="footer-top-area">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-lg-3">
                <div className="widget-item">
                  <div className="about-widget">
                    <div className="footer-logo-area">
                      <Link to="/" className="logoBox">
                        <img
                          style={{ height: "62px" }}
                          className="logo-main"
                          src="assets/images/logo.png"
                          width={"30%"}
                          alt="Logo"
                        />
                        <div className="text-black fw-bold tagLine">Style That Speaks, Outfits That Inspire</div>
                      </Link>
                    </div>
                    <p className="desc">
                    Blossom Apparel, crafting style since 2010. 
                    Elevate your wardrobe with our chic and casual ladies' ready-made clothes, along with adorable options for kids.
                    </p>
                    <ul>
                      <li>
                        <i className="ion-ios7-location-outline"></i> Shop #41/42 main bhowana bazar near polkadot shop, Faisalabad 38000
                      </li>
                      <li>
                        <i className="ion-ios7-email-outline"></i>{" "}
                        <Link to="mailto:support@zarkofficial.com">
                          support@zarkofficial.com
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="widget-item widget-item-one">
                  <h4 className="widget-title">INFORMATION</h4>
                  <div className="widget-menu-wrap">
                    <ul className="nav-menu">
                      <li>
                        <Link to="/about">About us</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact us</Link>
                      </li>
                      <li>
                        <Link to="/products">Our Products</Link>
                      </li>
                      <li>
                        <Link to="/signup">Register Now</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="widget-item widget-item-two">
                  <h4 className="widget-title">Social Media</h4>
                  <div className="widget-menu-wrap">
                    <ul className="nav-menu ms-3">
                      <li>
                        <Link
                          target="_blank"
                          to="https://www.facebook.com/zarkofficial06"
                        >
                          <UilFacebook /> Facebook
                        </Link>
                      </li>
                      <li>
                        <Link
                          target="_blank"
                          to="https://www.instagram.com/zark_official123"
                        >
                          <UilInstagram /> Instagram
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3">
                <div className="widget-item">
                  <h4 className="widget-title">newsletter</h4>
                  <div className="widget-newsletter">
                    <p>Sign up for our newsletter & promotions</p>
                    <div className="newsletter-form">
                      <form onSubmit={handleSubmit}>
                        <input
                          type="email"
                          name="email"
                          className="form-control rounded-5"
                          placeholder="email@example.com"
                          onChange={onChange}
                          value={credentials.email}
                        />
                        <button className="btn-submit rounded-5" type="submit">
                          {spinner === false ? "Subscribe" : <Spinner />}
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container d-flex justify-content-center">
            <div className="row">
              <div className="col-12">
                <p className="copyright">
                  © 2024 <span>Zark Official</span>.{" "}
                  <span>
                    Developed by{" "}
                    <Link
                      target="_blank"
                      to="https://www.tech-786.com"
                      className="text-dark fw-bold"
                    >
                      {" "}
                      Tech786
                    </Link>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="scroll-to-top" className="scroll-to-top">
          <span className="fa fa-angle-double-up"></span>
        </div>
      </footer>
      <ToastContainer />
    </div>
  );
}

export default Footer;
