import React from "react";
import { Link } from "react-router-dom";

function BreadCrumb(props) {
  return (
    <div className="page-header-area">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <div className="page-header-content">
              <nav className="breadcrumb-area">
                <ul className="breadcrumb">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-sep">/</li>
                  <li>{props.name}</li>
                </ul>
              </nav>
              <h4 className="title">{props.title}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BreadCrumb;
