import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './Pages/Home';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import Cart from './Pages/Cart';
import Checkout from './Pages/Checkout';
import Contact from './Pages/Contact';
import Products from './Pages/Products';
import Category from './Pages/Category';
import ProductDetails from './Pages/ProductDetails';
import About from './Pages/About';
import Account from './Pages/Account';
import Forget from './Pages/Forget';
import Error from './Pages/Error';
import Wishlist from './Pages/Wishlist';
import Receipt from './Pages/Receipt';
import { setTopRated } from './redux/actions/toprated';
import { setAllCategories } from './redux/actions/allcategories';
import { setNewArivalProduct } from './redux/actions/NewArivalProduct';
import { setMostView } from './redux/actions/MostView';
import { setBestSeller } from './redux/actions/bestSeller';
import { useDispatch } from 'react-redux';

function App() {

  const dispatch = useDispatch();

  useEffect(() => {
    import("bootstrap/dist/js/bootstrap");
    // Dispatch bestSeller
    getBestSeller();
    // Dispatch mostview
    getMostView();
    // Dispatch newArrival
    getNewArival();
    // categories dispatch
    getAllCategories();
    // top rated dispatch
    getTopRated();
  }, []);

  const getBestSeller = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/product/bestseller`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setBestSeller(result.data));
      })
      .catch((error) => console.log("Error:", error));
  };

  const getMostView = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/product/mostViewed`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setMostView(result.data));
      })
      .catch((error) => console.log("Error:", error));
  };

  const getNewArival = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/product/newArrival`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setNewArivalProduct(result.data));
      })
      .catch((error) => console.log("Error:", error));
  };

  const getAllCategories = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/category/getAll`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setAllCategories(result.data));
      })
      .catch((error) => console.log("Error:", error));
  };

  const getTopRated = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/product/topRated`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setTopRated(result.data));
      })
      .catch((error) => console.log("Error:", error));
  };

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/wishlist" element={<Wishlist />} />
        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/products" element={<Products/>} />
        <Route exact path="/products/:cat" element={<Products/>} />
        <Route exact path="/collection/:subcat" element={<Category/>} />
        <Route exact path="/product/:id" element={<ProductDetails/>} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/account" element={<Account />} />
        <Route exact path="/forget" element={<Forget />} />
        <Route exact path="/about" element={<About />} />
        <Route path="/order/:uuid" element={<Receipt/>} />
        <Route path="*" element={<Error/>} />
      </Routes>
    </Router>
  )
}

export default App;
