import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../Loader/Spinner";


function AccountDetails() {
  const userData = useSelector((state) => state.userDetails.user);
  const userId = userData.id;
  const [spinner, setSpinner] = useState(false);


  const [credentials, setCredentials] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""

  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Validations
    if (credentials.oldPassword.length === 0) {
      toast("Please Enter Old Password");
      setSpinner(false);
      return;
    }
    if (
      credentials.newPassword.length !== credentials.confirmPassword.length &&
      String(credentials.newPassword) !== String(credentials.confirmPassword)
    ) {
      toast("Password Not Match");
      setSpinner(false);
      return;
    }
    setSpinner(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
      var urlencoded = new URLSearchParams();
      urlencoded.append("userId", userId);
      urlencoded.append("oldPassword", credentials.oldPassword);
      urlencoded.append("newPassword", credentials.newPassword);
  
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };
  
      fetch(
        `${process.env.REACT_APP_API_URL}user/user/updatePassword`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            toast(response.message); 
            setSpinner(false);
            setCredentials({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
          } else if (response.status === "error") {
            toast(response.message);
            setSpinner(false);
            setCredentials({
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
          } else {
            toast("Internal Server Error"); 
            setSpinner(false);
          }
        })
        .catch((error) => {
          console.log(error);
          toast("Internal Server Error"); 
          setSpinner(false);
        });
    } catch (error) {
      console.log(error);
      toast("Internal Server Error"); 
      setSpinner(false);
    }
  };
  
  return (
    <div
      className="tab-pane fade"
      id="v-pills-messages"
      role="tabpanel"
      aria-labelledby="v-pills-messages-tab"
      tabindex="0"
    >
      <h4 className="text-start py-3">Account Details</h4>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label className="pb-1">First Name*</label>
              <input
                type="text"
                name="first_name"
                required
                disabled
                value={userData.firstName}
                className="form-control "
                id="floatingInput"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="pb-1">Last Name*</label>
              <input
                type="text"
                name="last_name"
                required
                disabled
                value={userData.lastName}
                className="form-control  mb-3"
                id="floatingInputValue"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label className="pb-1">Email*</label>
              <input
                type="email"
                name="email"
                required
                disabled
                value={userData.email}
                className="form-control  mb-3"
                id="floatingInputValue"
              />
            </div>
          </div>
          <div className="">
            <h4 className="text-start py-3">Change Password</h4>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="pb-1">Current Password</label>
              <input
                type="password"
                name="oldPassword"
                required
                onChange={onChange}
                value={credentials.oldPassword}
                className="form-control  mb-3"
                id="cpass"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="pb-1">New Password</label>
              <input
                type="password"
                name="newPassword"
                required
                onChange={onChange}
                value={credentials.newPassword}
                className="form-control  mb-3"
                id="npass"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label className="pb-1">Confirm Password</label>
              <input
                type="password"
                name="confirmPassword"
                required
                onChange={onChange}
                value={credentials.confirmPassword}
                className="form-control  mb-3"
                id="cfpass"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group d-flex justify-content-end">
              <button
                className="btn btn-block btn-pink btn-lg rounded-5 text-white"
                type="submit"
              >
                {spinner === false ? "Change Password" : <Spinner />}
              </button>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default AccountDetails;