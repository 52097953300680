import React from "react";

function RecentOrders(props) {
  return (
    <div
      className="tab-pane fade"
      id="v-pills-profile"
      role="tabpanel"
      aria-labelledby="v-pills-profile-tab"
      tabindex="0"
    >
      <h3 className="text-start py-3">Recent Orders</h3>
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Order #</th>
            <th scope="col">Product Details</th>
            <th scope="col">Total</th>
            <th scope="col">Status</th>
            <th scope="col">Created At</th>
          </tr>
        </thead>
        <tbody>
          {props.orders &&
            props.orders.map((order, index) => (
              <tr>
                <td>{order.orderNo}</td>
                <td>
                  {order.products && order.products.map((product,index) => (
                    <div >
                      {product.title} {' '} x{product.quantity}
                    </div>
                  ))}
                  </td>
                <td>{order.totalPrice}</td>
                <td>{order.status}</td>
                <td>{order.createdAt}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default RecentOrders;
