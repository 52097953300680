import React from "react";
import { useSelector } from "react-redux";

function Dashboard(props) {
  const cartData = useSelector((state) => state.cartList.cart);
  return (
    <div
      className="tab-pane fade show active"
      id="v-pills-home"
      role="tabpanel"
      aria-labelledby="v-pills-home-tab"
      tabindex="0"
    >
      <h4 className="text-start">Welcome Back, </h4>
      <div className="row">
        <div className="col-md-6">
          <div className="card-body bg-light text-white p-4 rounded-3 text-center">
            <h5 className="card-title ">Cart items</h5>
            <span className="cart-quantity bg-dark p-2 rounded-5">{cartData.length}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card-body bg-light text-white p-4 rounded-3 text-center">
            <h5 className="card-title text-center">Total Orders</h5>
            <span className="cart-quantity bg-dark p-2 rounded-5">{props.totalOrders}</span>

          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
