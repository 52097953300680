import React, { useEffect, useState } from "react";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/actions/userProfile";
import { setWishList } from "../redux/actions/wishlist";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../Components/Loader/Spinner";

function Login() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [spinner, setSpinner] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    setSpinner(true);

    // Validations
    if (credentials.email.length === 0) {
      toast("Please enter Email");
      setSpinner(false);
      return;
    }
    if (credentials.password.length === 0) {
      toast("Please Enter Your Password");
      setSpinner(false);
      return;
    }

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("email", credentials.email);
      urlencoded.append("password", credentials.password);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(`${process.env.REACT_APP_API_URL}user/user/login`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            localStorage.setItem("id", response.data.id);
            console.log(response);
            dispatch(setUser(response.data.userData));
            dispatch(setWishList(response.data.wishlist));
            setCredentials({
              email: "",
              password: "",
            });
            navigate("/");
          } else if (response.status === "error") {
            toast(response.message);
            setSpinner(false);
            setCredentials({
              email: "",
              password: "",
            });
          } else {
            toast("Internal Server Error");
            setSpinner(false);
          }
        })
        .catch((error) => {
          console.error(error);
          toast("Internal Server Error");
          setSpinner(false);
        });
    } catch (error) {
      console.error(error);
      toast("Internal Server Error");
      setSpinner(false);
    }
  };

  return (
    <div>
      <Navigation />
      <main className="main-content">
        <div className="page-header-area">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="page-header-content">
                  <nav className="breadcrumb-area">
                    <ul className="breadcrumb">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-sep">/</li>
                      <li>Account</li>
                    </ul>
                  </nav>
                  <h4 className="title">Account</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="account-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-8 m-auto">
                <div className="account-form-wrap">
                  <div className="login-form">
                    <div className="content">
                      <h4 className="title">Login</h4>
                      <p>Please login using account detail below.</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="email"
                              placeholder="Email"
                              onChange={onChange}
                              value={credentials.email}
                              name="email"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="password"
                              placeholder="Password"
                              onChange={onChange}
                              value={credentials.password}
                              name="password"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="login-form-group">
                            <button className="btn-sign rounded" type="submit">
                              {spinner === false ? "Login" : <Spinner />}
                            </button>
                            <Link className="btn-pass-forgot" to="/">
                              Forgot your password?
                            </Link>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="account-optional-group">
                            <Link className="btn-create" to="/signup">
                              Create New Account
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default Login;
