import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function AreaWrapper() {
  const navigate = useNavigate();

  const allCategory = useSelector((state) => state.allCategories.allcategories);
  let menCatId = null;
  let womenCatId = null;

  const findCategoryIdByName = (categoryName) => {
    const category = allCategory?.find(
      (category) => category.name === categoryName
    );
    return category ? category.id : null;
  };

  menCatId = findCategoryIdByName("Men Shawl");
  womenCatId = findCategoryIdByName("Women Shawl");

  return (
    <div className="wrapper">
      <section className="feature-area">
        <div className="container pb-1">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="feature-icon-box">
                <div className="inner-content rounded">
                  <div className="icon-box">
                    <i className="icon ei ei-icon_pin_alt"></i>
                  </div>
                  <div className="content">
                    <h5 className="title rounded">Shipping Within Pakistan</h5>
                    <p>Shipping Fee Rs.199</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="feature-icon-box">
                <div className="inner-content rounded">
                  <div className="icon-box">
                    <i className="icon ei ei-icon_headphones"></i>
                  </div>
                  <div className="content">
                    <h5 className="title rounded">Support 24/7</h5>
                    <p>Contact us 24 hours a day</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="feature-icon-box">
                <div className="inner-content mb-0 rounded">
                  <div className="icon-box">
                    <i className="icon ei ei-icon_creditcard"></i>
                  </div>
                  <div className="content">
                    <h5 className="title rounded">100% secure payment</h5>
                    <p>Your payment are safe with us.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container pb-1">
          <div className="row">
            <div className="col-md-6 col-lg-6">
              <div className="feature-icon-box">
                <div
                  className="inner-content p-5 rounded"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  {/* Background Image with Blur Effect */}
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage: 'url("/assets/images/photos/mens.jpeg")',
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundAttachment: "fixed",
                      backgroundRepeat: "no-repeat",
                      filter: "blur(1px)",
                      zIndex: 1,
                    }}
                  ></div>

                  {/* Content */}
                  <div
                    className="content"
                    style={{ position: "relative", zIndex: 1 }}
                  >
                    <h5 className="title fs-2 fw-bolder text-black shadow-lg rounded">
                      Kids Designs
                    </h5>
                    {/* <p className="sub-title fs-5 fw-bold shadow text-black">
                      
                    </p> */}
                    <button
                      className="btn-submit rounded-5 mt-5"
                      onClick={() => navigate("/products/" + menCatId)}
                    >
                      Shop Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="feature-icon-box">
                <div
                  className="inner-content p-5 rounded"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                  }}
                >
                  {/* Background Image with Blur Effect */}
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundImage:
                        'url("/assets/images/photos/women.jpeg")',
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundAttachment: "fixed",
                      backgroundRepeat: "no-repeat",
                      filter: "blur(1px)",
                      zIndex: 1,
                    }}
                  ></div>

                  {/* Content */}
                  <div
                    className="content"
                    style={{ position: "relative", zIndex: 1 }}
                  >
                    <h5 className="title fs-2 fw-bolder shadow-lg text-black rounded">
                      Ladies Designs
                    </h5>
                    {/* <p className="sub-title fs-5 shadow fw-bold text-black">
                      Flat 20% Off
                    </p> */}
                    <button
                      className="btn-submit rounded-5 mt-5"
                      onClick={() => navigate("/products/" + womenCatId)}
                    >
                      Shop Now
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-6 col-lg-4">
              <div className="feature-icon-box">
                <div className="inner-content mb-0 p-5 rounded">
                  <div className="content">
                    <h5 className="title fs-3 fw-bolder rounded">Men Suits</h5>
                    <p className="sub-title fw-bold">Flat 20% Off</p>
                    <button className="btn-submit rounded-5">Shop Now</button>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AreaWrapper;
