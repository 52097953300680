import React, { useEffect } from "react";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setWishList } from "../redux/actions/wishlist";
import { setCart } from "../redux/actions/cart";
import { toast } from "react-toastify";

function Wishlist() {
  const dispatch = useDispatch();
  const wishlist = useSelector((state) => state.wishList.wishlist);
  const userData = useSelector((state) => state.userDetails.user);
  const cartData = useSelector((state) => state.cartList.cart);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData.length === 0) {
      navigate('/login');
    }
  }, []);


  const handleAddToCart = (product) => {
    if (product.quantity - product.sales === 0) {
      toast("Product is Out of Stock");
    } else {
      const cartItem = {
        id: product.id,
        title: product.title,
        price:
          product.discount === 0
            ? product.salePrice
            : product.salePrice -
              (product.salePrice * product.discount) / 100,
        colors: product.color,
        avgRating: product.avgRating,
        description: product.description,
        thumbnail: product.thumbnail,
        quantity: 1,
      };

      const existingCartItem = cartData.find((item) => item.id === cartItem.id);
      if (existingCartItem) {
        existingCartItem.quantity += 1; 
        dispatch(setCart([...cartData]));
      } else {
        dispatch(setCart([...cartData, { ...cartItem }]));
      }
      toast("Product added to cart");
    }
  };

  const removeFromWishlist = (productId) => {
    console.log(productId);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("productId", productId);
    urlencoded.append("userId", userData.id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://apis.fazalshawl.com/user/wishlist/removeFromWishlist",
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        console.log(response);
        dispatch(setWishList(response.data));
        toast("Deleted Successfully");
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div>
      <Navigation />
      <main className="main-content">
        <div className="page-header-area">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="page-header-content">
                  <nav className="breadcrumb-area">
                    <ul className="breadcrumb">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-sep">/</li>
                      <li>Wishlist</li>
                    </ul>
                  </nav>
                  <h4 className="title">Wishlist</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="product-area product-wishlist-area">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="shopping-wishlist-table table-responsive">
                  {wishlist.length === 0 ? (
                    <p className="text-center bg-light p-3">
                      <span className="text-muted fs-5">Wishlist is empty</span></p>
                  ) : (
                    <table className="table text-center">
                      <thead>
                        <tr>
                          <th className="product-remove ps-1">Delete</th>
                          <th className="product-thumb">&nbsp;</th>
                          <th className="product-name">Product</th>
                          <th className="product-stock-status">Status</th>
                          <th className="product-price">Price</th>
                          <th className="product-action">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {wishlist.map((item) => (
                          <tr key={item.id} className="cart-wishlist-item">
                            <td className="product-remove">
                              <button
                                className="btn"
                                onClick={() =>
                                  removeFromWishlist(item.product.id)
                                }
                              >
                                <i className="fa fa-trash-o"></i>
                              </button>
                            </td>
                            <td className="product-thumb">&nbsp;</td>
                            
                            <td className="product-thumb py-3">
                              <Link
                                to={`/product/${item.product.title}`}
                              >
                                <img
                                  className="rounded"
                                  style={{ width: "40%" }}
                                  src={`https://admin.zarkofficial.com/products/${item.product.thumbnail}`}
                                  alt="thumbnail"
                                />
                              </Link>
                              <p>
                                <Link
                                  className="text-black fw-bold"
                                  to={`/product/${item.product.title}`}
                                >
                                  {item.product.title}
                                </Link>
                              </p>
                            </td>
                            <td className="product-stock-status">
                              {item.product.quantity - item.product.sale === 0 ? (
                                <span className="stock bg-danger">
                                  Out of Stock
                                </span>
                              ) : (
                                <span className="stock">In Stock</span>
                              )}
                            </td>
                            <td className="product-price">
                              <span className="price">
                                Rs. {item.product.salePrice}
                              </span>
                            </td>
                            <td className="product-action">
                              {item.product.quantity - item.product.sales === 0 ? (
                                <button className="btn-cart " disabled>
                                  Add to cart
                                </button>
                              ) : (
                                <button
                                  className="btn-cart ms-auto"
                                  onClick={() => handleAddToCart(item.product)}
                                >
                                  Add to cart
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Wishlist;
