import React from "react";
import ProductComp from "../ProductComp";
import { useSelector } from "react-redux";

function BestSeller() {
  const bestSellerData = useSelector((state) => state.bestSeller.bestseller);

  return (
    <div
      className="tab-pane fade show active"
      id="bestSeller"
      role="tabpanel"
      aria-labelledby="best-seller-tab"
    >
      <div className="row">
        {bestSellerData?.map((product) => (
          <div className="col-sm-6 col-lg-4 col-xl-3">
            <ProductComp
            id={product.id} 
            title={product.title} 
            discount={product.discount} 
            quantity={product.quantity}
            sales={product.sales}
            price={product.salePrice}
            rating={product.avgRating}
            thumbnail={product.thumbnail}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default BestSeller;
