import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AddReview(props) {
  const [credentials, setCredentials] = useState({

    username: "",
    email: "",
    review: "",
    rating: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (credentials.username.length === 0) {
      toast("Please enter Your Name");
      return;
    }
    if (credentials.email.length === 0) {
      toast("Please Enter Your Email");
      return;
    }
    if (credentials.review.length === 0) {
      toast("Please Enter Your Reviews");
      return;
    }
    if (isNaN(Number(credentials.rating)) || credentials.rating < 1 || credentials.rating > 5) {
      toast("Please enter a valid rating between 1 and 5");
      return;
    }

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("productId", props.id);
      urlencoded.append("username", credentials.username);
      urlencoded.append("email", credentials.email);
      urlencoded.append("review", credentials.review);
      urlencoded.append("rating", credentials.rating);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded.toString(), // Use toString() to get the correct format
        redirect: "follow",
      };

      fetch(`${process.env.REACT_APP_API_URL}user/review/addRecord`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            toast("Review Submitted Successfully");
            setCredentials({
              username: "",
              email: "",
              rating: "",
              review: "",
            });
          } else if (response.status === "error") {
            toast(response.message);
          } else {
            toast("Internal Server Error");
          }
        })
        .catch((error) => {
          console.error(error);
          toast("Internal Server Error");
        });
    } catch (error) {
      console.error(error);
      toast("Internal Server Error");
    }
  };

  return (
    <div className="reviews-form-area show">
      <h4 className="title">Write a review</h4>
      <div className="reviews-form-content">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="for_name">Name</label>
                <input
                  id="for_name"
                  className="form-control"
                  type="text"
                  placeholder="Enter your name"
                  name="username"
                  onChange={onChange}
                  value={credentials.username}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="for_email">Email</label>
                <input
                  id="for_email"
                  className="form-control"
                  type="email"
                  placeholder="hello@example.com"
                  name="email"
                  onChange={onChange}
                  value={credentials.email}
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <span className="title">Rating</span>
                <ul className="review-rating">
                  {[1, 2, 3, 4, 5].map((ratingValue) => (
                    <li
                      key={ratingValue}
                      className={`fa fa-star${credentials.rating >= ratingValue ? '' : '-o'}`}
                      onClick={() => setCredentials({ ...credentials, rating: ratingValue })}
                    ></li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label htmlFor="for_comment">Body of Review (1200)</label>
                <textarea
                  id="for_comment"
                  className="form-control"
                  placeholder="Write your comments here"
                  maxLength={1200}
                  name="review"
                  onChange={onChange}
                  value={credentials.review}
                ></textarea>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-submit-btn">
                <button type="submit" className="btn-submit">
                  Post comment
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddReview;
