import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import html2canvas from "html2canvas";

function Receipt() {
  const userData = useSelector((state) => state.userDetails.user);
  const [latestOrder, setLatestOrder] = useState(null);
  const [cartData, setCartData] = useState([]);

  const params = useParams();

  useEffect(() => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_API_URL}user/order/getByUuid/${params.uuid}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setLatestOrder(result.data);
          setCartData(result.data.products);
          console.log(result.data);
        } else {
          console.log("Error:", result.error);
        }
      })
      .catch((error) => console.log("Error:", error));
  }, []);
  const calculateSubtotal = () => {
    return (
      cartData &&
      cartData.reduce((total, item) => {
        const discount=(item.salePrice/100)*item.discount
        const salePrice=item.salePrice-discount
        return total + salePrice * item.quantity;
      }, 0)
    );
  };

  const calculateTotal = () => {
    return calculateSubtotal() + 199;
  };

  const billingAddressObj = latestOrder
    ? JSON.parse(latestOrder.billingAddress)
    : null;
  const shippingAddressObj = latestOrder
    ? JSON.parse(latestOrder.shippingAddress)
    : null;

  const handlePrintButtonClick = () => {
    const buttonsContainer = document.querySelector(".buttons-container");
    if (buttonsContainer) {
      buttonsContainer.style.display = "none";
    }
    html2canvas(document.querySelector(".main-content")).then((canvas) => {
      if (buttonsContainer) {
        buttonsContainer.style.display = "";
      }
      const screenshot = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = screenshot;
      link.download = "ordersummery.png";
      link.click();
    });
  };

  return (
    <div className="vh-100">
      <main className="main-content">
        <section className="product-area product-information-area">
          <div className="container">
            <div className="product-information">
              <div className="row">
                {latestOrder && (
                  <div className="col-md-7 orders">
                    <div className="thumb">
                      <img
                        src="assets/images/logo.png"
                        alt="cart_img"
                        width={300}
                      />
                    </div>
                    <div className="order-txt my-3">
                      <p>
                        Order No <strong>{latestOrder.orderNo}</strong>
                      </p>
                    </div>
                    <div className="order-txt">
                      <h4 className="title">Thank You For Shopping</h4>
                    </div>
                    <div className="card border-2 mt-3">
                      <div className="card-body orders">
                        <h4 className="title mb-2">
                          Your Order Will Be Confirmed
                        </h4>
                        <p>
                          You'll recieve a confirmation email with your order
                          number
                        </p>
                      </div>
                    </div>
                    <div className="card border-2 mt-2">
                      <div className="card-body">
                        <div className="row">
                          <div className="headintitle">
                            <h3 className="title">Order Details</h3>
                          </div>
                          <div className="col-md-6 mt-n2">
                            <div className="py-2">
                              <h4 className="title mb-2">
                                Contact information
                              </h4>
                              <p className="fs-6">{latestOrder.email}</p>
                            </div>
                            <div>
                              <h4 className="title my-2">Shipping Address</h4>
                              <div className="bd-example-snippet bd-code-snippet">
                                <div className="bd-example m-0 border-0">
                                  <ul className="list-unstyled mt-2">
                                    <li>
                                      <p className="fs-6">
                                        {userData.firstName} {userData.lastName}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="fs-6">
                                        {billingAddressObj.address}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="fs-6">
                                        {latestOrder.city}{" "}
                                        {billingAddressObj.zipCode}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="fs-6">
                                        {latestOrder.state}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="fs-6">
                                        {latestOrder.country}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="fs-6">
                                        {latestOrder.phone}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="py-2">
                              <h4 className="title mb-2">Payment Method</h4>
                              <div>
                                <p className="fs-6">Cash On Delivery</p>
                              </div>
                            </div>
                            <div>
                              <h4 className="title my-2">Billing Address</h4>
                              <div className="bd-example-snippet bd-code-snippet">
                                <div className="bd-example m-0 border-0">
                                  <ul className="list-unstyled mt-2">
                                    <li>
                                      <p className="fs-6">
                                        {userData.firstName} {userData.lastName}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="fs-6">
                                        {shippingAddressObj.address}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="fs-6">
                                        {latestOrder.city}{" "}
                                        {shippingAddressObj.zipCode}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="fs-6">
                                        {latestOrder.state}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="fs-6">
                                        {latestOrder.country}
                                      </p>
                                    </li>
                                    <li>
                                      <p className="fs-6">
                                        {latestOrder.phone}
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="col-md-5 mt-md-5 mt-4 pt-md-3 pt-0">
                  <div className="shipping-cart-subtotal-wrapper">
                    <div className="shipping-cart-subtotal">
                      {cartData &&
                        cartData.map((item, index) => (
                          <div key={index} className="shipping-cart-item">
                            <div className="thumb">
                              <img
                                src={`https://admin.zarkofficial.com/products/${item.thumbnail}`}
                                alt="cart_img"
                              />
                              <span className="quantity">{item.quantity}</span>
                            </div>
                            <div className="content">
                              <h4 className="title">{item.title}</h4>
                              <span className="price">
                                Rs.{item.salePrice.toFixed(2)}
                              </span>
                            </div>
                          </div>
                        ))}
                      <div className="shipping-subtotal">
                        <p>
                          <span>Subtotal</span>
                          <span>
                            <strong>Rs.{calculateSubtotal().toFixed(2)}</strong>
                          </span>
                        </p>
                        <p>
                          <span>Shipping</span>
                          <span>
                            <strong>Rs.199.00</strong>
                          </span>
                        </p>
                      </div>
                      <div className="shipping-total">
                        <p className="total">Total</p>
                        <p className="price">
                          <span className="usd">Rs.</span>
                          {calculateTotal().toFixed(2)}
                        </p>
                      </div>
                      <div className="buttons-container">
                        <div className=" d-flex justify-content-center mt-3">
                          <Link
                            to="/products"
                            className="btn btn-block btn-dark p-2 mt-3"
                          >
                            Continue Shopping
                          </Link>
                          <button
                            className="btn btn-block btn-dark p-2 mt-3 ms-2"
                            onClick={handlePrintButtonClick}
                          >
                            Print
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Receipt;
