import React, { useEffect } from "react";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import Items from "../Components/Shop/Items";
import Sidebar from "../Components/Shop/Sidebar";
import BreadCrumb from "../Components/BreadCrumb/BreadCrumb";
import { setAllProduct } from "../redux/actions/allProduct";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function Category() {
  const params = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  useEffect(() => {
    // Dispatch all products
    getAllProduct();
  }, []);

  const getAllProduct = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    if (params?.cat === undefined) {
      await fetch(
        `${process.env.REACT_APP_API_URL}user/product/getAll?subCategoryName=${params.subcat}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          dispatch(setAllProduct(result.data));
          console.log(result.data);
          localStorage.setItem("page", result.nextPage);
        })
        .catch((error) => console.log("Error:", error));
    } else {
      await fetch(
        `${process.env.REACT_APP_API_URL}user/product/getAll?subCategoryName=${params.subcat}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          dispatch(setAllProduct(result.data));
          localStorage.setItem("page", result.nextPage);
        })
        .catch((error) => console.log("Error:", error));
    }
  };

  return (
    <div>
      <Navigation />
      <BreadCrumb title="Category" name="Products" />
      <main className="main-content">
        <section className="product-area product-inner-area">
          <div className="container">
            <div className="row">
              <Items />
              <Sidebar />
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Category;
