import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setWishList } from "../redux/actions/wishlist";
import { setCart } from "../redux/actions/cart";

function ProductComp(props) {
  const userinfo = useSelector((state) => state.userDetails.user);
  const cartData = useSelector((state) => state.cartList.cart);
  const dispatch = useDispatch();
  const handleAddToWishlist = () => {
    if (userinfo.length === 0) {
      toast("You Need to Login First!!!");
    } else {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
        urlencoded.append("userId", userinfo.id);
        urlencoded.append("productId", props.id);

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: urlencoded,
          redirect: "follow",
        };

        fetch(
          `${process.env.REACT_APP_API_URL}user/wishlist/addToWishlist`,
          requestOptions
        )
          .then((response) => response.json())
          .then((response) => {
            if (response.status === "success") {
              toast("Product Added to Wishlist");
              dispatch(setWishList(response.data));
            } else if (response.status === "error") {
              toast(response.message);
            } else {
              toast("Internal Server Error");
            }
          })
          .catch((error) => {
            console.error(error);
            toast("Internal Server Error");
          });
      } catch (error) {
        console.error(error);
        toast("Internal Server Error");
      }
    }
  };
  const handleAddToCart = () => {
    const quantity = props?.quantity;
    if (props?.quantity - props?.sales === 0) {
      toast("Product is Out of Stock");
    } else {
      const cartItem = {
        id: props?.id,
        title: props?.title,
        price:
          props?.discount === 0
            ? props?.price
            : props?.price - (props?.price * props?.discount) / 100,
        colors: props?.color,
        avgRating: props?.avgRating,
        description: props?.description,
        thumbnail: props?.thumbnail,
      };

      const existingCartItem = cartData.find(
        (item) => item?.id === cartItem?.id
      );
      if (existingCartItem) {
        existingCartItem.quantity += quantity;
        dispatch(setCart([...cartData]));
      } else {
        dispatch(setCart([...cartData, { ...cartItem, quantity }]));
      }
      toast("Product added to cart");
    }
  };

  return (
    <>
      <div className="product-item">
        <div className="inner-content">
          <div className="product-thumb">
            <div style={{ objectFit: "contain" }}>
              <Link to={`/product/${props?.id}`} className="text-center">
                <img
                  className="w-100 rounded product-image"
                  src={`https://admin.zarkofficial.com/products/${props?.thumbnail}`}
                  alt="HasTech"
                  style={{
                    height: "400px",
                    maxWidth: "100%",
                    width: "100%",
                  }}
                  loading="lazy"
                />
              </Link>
            </div>
            {props?.quantity - props?.sales === 0 ? (
              <span className="sale-title sticker">SoldOut</span>
            ) : (
              ""
            )}
            {props?.discount === 0 ? (
              ""
            ) : props?.quantity - props?.sales > 0 ? (
              <span className="sale-title sticker">Sale</span>
            ) : (
              ""
            )}

            {props.discount === 0 ? (
              ""
            ) : props?.quantity - props?.sales > 0 ? (
              <span className="percent-count sticker">-{props.discount}%</span>
            ) : (
              ""
            )}
            <div className="product-action">
              <div className="addto-wrap">
                <button
                  className="btn btn-light"
                  onClick={() => handleAddToWishlist(props.id)}
                >
                  <i className="zmdi zmdi-favorite-outline "></i>
                </button>
                <button
                  className="btn btn-light ms-2 "
                  onClick={handleAddToCart}
                >
                  <i class="zmdi zmdi-shopping-cart-plus icon"></i>{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="product-desc">
            <div className="product-info">
              <h4 className="title">
                <Link to={`/product/${props?.title}`}>{props?.title}</Link>
              </h4>
              <div className="star-content">
                {Array.from({ length: 5 }).map((_, index) => (
                  <i
                    key={index}
                    className={
                      index < props?.rating ? "fa fa-star" : "fa fa-star-o"
                    }
                  ></i>
                ))}
              </div>
              <div className="prices">
                {props?.discount === 0 ? (
                  <span className="price">Rs.{props?.price}</span>
                ) : (
                  <span className="price">
                    Rs.{props?.price - (props?.price * props?.discount) / 100}
                  </span>
                )}
                {props?.discount === 0 ? (
                  ""
                ) : (
                  <span className="price-old">Rs.{props?.price}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductComp;
