import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAllProduct } from "../../redux/actions/allProduct";
import { Accordion } from "react-bootstrap";

function Sidebar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_API_URL}user/product/getAll`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        dispatch(setAllProduct(result.data));
      })
      .catch((error) => console.log("Error:", error));

    window.scrollTo(0, 0);
  }, []);

  const topRatedProduct = useSelector((state) => state.topRated.toprated);
  const allCategory = useSelector((state) => state.allCategories.allcategories);
  const [search, setSearch] = useState({
    search: "",
  });

  const onChange = (e) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
    handleSearch();
  };
  const handleData = async (subCategory, name) => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_API_URL}user/product/getAll?subCategoryId=${subCategory}`
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setAllProduct(result.data));
        localStorage.setItem("page", result.nextPage);
        navigate('/collection/' + String(name));
      })
      .catch((error) => console.log("Error:", error));
  };

  const handleSearch = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/product/search?title=${search.search}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setAllProduct(result.data));
      })
      .catch((error) => console.log("Error:", error));
  };

  return (
    <div className="col-lg-3 order-0 order-lg-1">
      <div className="product-sidebar-wrapper">
        <div className="product-sidebar-item">
          <h4 className="product-sidebar-title">Search</h4>
          <div className="product-sidebar-body">
            <div className="product-sidebar-search-form">
              <form>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search our store"
                    name="search"
                    onChange={onChange}
                  />
                  <button type="submit" className="btn-src">
                    <i className="zmdi zmdi-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="product-sidebar-item">
          <h4 className="product-sidebar-title">Categories</h4>
          <div className="product-sidebar-body">
            <div className="product-sidebar-nav-menu">
              <div className="product-view-side ">
                <div className="view-prod">
                  <Accordion>
                    {allCategory.map((category, index) => (
                      <Accordion.Item eventKey={index.toString()} key={index}>
                        <Accordion.Header>{category.name}</Accordion.Header>
                        <Accordion.Body>
                          <div className="accordion-body">
                            <ul className="no-spacing-ul">
                              {category.subCategories.map((sub, subIndex) => (
                                <li
                                  className="no-spacing-li ms-4"
                                  key={subIndex}
                                >
                                  <Link
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handleData(sub.id,sub.name)}
                                  >
                                    - {sub.name}
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-sidebar-item d-none d-md-block">
          <h4 className="product-sidebar-title">Top Rated Products</h4>
          <div className="product-sidebar-body">
            {topRatedProduct.map((product) => (
              <div className="product-sidebar-item">
                <div className="thumb">
                  <Link to={`/product/${product.id}`}>
                    <img
                      className="w-100 rounded"
                      src={`https://admin.zarkofficial.com/products/${product.thumbnail}`}
                      alt="HasTech"
                    />
                  </Link>
                </div>
                <div className="content pt-0" style={{ marginTop: "-4px" }}>
                  <h4 className="title mb-0">
                    <Link to={`/product/${product.title}`}>
                      {product.title}
                    </Link>
                  </h4>
                  <div className="product-review">
                    {Array.from({ length: 5 }).map((_, index) => (
                      <i
                        key={index}
                        className={
                          index < product.avgRating
                            ? "fa fa-star"
                            : "fa fa-star-o"
                        }
                      ></i>
                    ))}
                  </div>
                  <div className="prices">
                    <small>
                      {product.discount === 0 ? (
                        <span className="price" style={{ color: "#264f4b" }}>
                          Rs.{product.salePrice}{" "}
                        </span>
                      ) : (
                        <>
                          <span className="price" style={{ color: "#264f4b" }}>
                            Rs.
                            {product.salePrice -
                              (product.salePrice * product.discount) / 100}
                          </span>{" "}
                          <span
                            className="price-old"
                            style={{ textDecoration: "line-through" }}
                          >
                            Rs.{product.salePrice}
                          </span>
                        </>
                      )}
                    </small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
