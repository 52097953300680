import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swiper from "swiper";
import "swiper/css";

function HeroSection() {
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    const carouselSlider = new Swiper(".default-slider-container", {
      slidesPerView: 1,
      slidesPerGroup: 1,
      loop: true,
      speed: 500,
      spaceBetween: 0,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      autoplay: {
        delay: 4000,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      on: {
        init: function () {
          setSwiper(this); // Save the Swiper instance in state
        },
      },
    });

    return () => {
      // Clean up the Swiper instance when the component unmounts
      carouselSlider.destroy();
    };
  }, []);

  const goToNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const goToPrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };

  return (
    <div className="wrapper">
      <section className="home-slider-area">
        <div className="swiper-container swiper-slide-gap home-slider-container default-slider-container">
          <div className="swiper-wrapper home-slider-wrapper slider-default">
            <div className="swiper-slide">
              <div
                className="slider-content-area d-md-flex justify-content-md-center"
                style={{
                  backgroundImage: "url('assets/images/slider/slider-01.png')",
                }}
              >
                <div className="slider-content p-2">
                  <h5 className="sub-title text-white">Ladies Casual Designs</h5>
                  <h2 className="title text-white">NEW ARRIVAL</h2>
                  <p
                    className="fw-bold text-white"
                    style={{ textShadow: "2px 2px 2px rgba(0, 0, 0, 1)" }}
                  >
                    For over one decades, we have perfected the timeless craft,
                    creating casual for both kids and ladies that epitomize
                    comfort and style.
                  </p>
                  <Link className="btn-slider" to="/products">
                    Shop Now
                  </Link>
                </div>
              </div>
            </div>
            <div className="swiper-slide">
              <div
                className="slider-content-area d-md-flex justify-content-md-center"
                style={{
                  backgroundImage: "url('assets/images/slider/slider-02.png')",
                }}
              >
                <div className="slider-content p-2">
                  <h5 className="sub-title" style={{}}>Ladies Fancy Designs</h5>
                  <h2 className="title" style={{}}>NEW ARRIVAL</h2>
                  <p
                    className="fw-bold"
                    style={{ textShadow: "1px 1px 1px rgba(0, 0, 0, 1)"}}
                  >
                    Explore our collection and experience the timeless beauty
                    and craftsmanship that define us. Discover the world of
                    Zark Officail today.
                  </p>
                  <Link className="btn-slider" to="/products">
                    Shop Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-button-next" onClick={goToNext}>
            <i className="ion-ios7-arrow-right"></i>
          </div>
          <div className="swiper-button-prev" onClick={goToPrev}>
            <i className="ion-ios7-arrow-left"></i>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HeroSection;
