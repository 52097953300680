import React from "react";
import ProductComp from "../ProductComp";
import { useSelector } from "react-redux";

function MostView() {
  const mostViewData = useSelector((state) => state.mostView.mostview);
  return (
    <div
      className="tab-pane fade"
      id="mostView"
      role="tabpanel"
      aria-labelledby="most-view-tab"
    >
      <div className="row">
        {mostViewData?.map((mostviewproduct) => (
          <div className="col-sm-6 col-lg-4 col-xl-3">
            <ProductComp
              id={mostviewproduct.id}
              title={mostviewproduct.title}
              discount={mostviewproduct.discount}
              quantity={mostviewproduct.quantity}
              sales={mostviewproduct.sales}
              price={mostviewproduct.salePrice}
              rating={mostviewproduct.avgRating}
              thumbnail={mostviewproduct.thumbnail}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default MostView;
