import React, { useState } from "react";
import ProductComp from "../ProductComp";
import { useDispatch, useSelector } from "react-redux";
import { setAllProduct } from "../../redux/actions/allProduct";

function Items() {
  const getAllProducts = useSelector((state) => state.allProduct.allproduct);
  const dispatch = useDispatch();
  const [magic, setMagic] = useState(true);

  const handlePage = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(`${localStorage.getItem("page")}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          const final = getAllProducts?.concat(result.data);
          dispatch(setAllProduct(final));
          if (result.data.length === 0) {
            setMagic(false);
          }
          localStorage.setItem("page", result.nextPage);
        } else {
          return;
        }
      })
      .catch((error) => console.log("Error:", error));
  };
  return (
    <div className="col-lg-9 order-1 order-lg-2">
      <div className="product-body-wrap">
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-grid"
            role="tabpanel"
            aria-labelledby="nav-grid-tab"
          >
            {getAllProducts?.length === 0 ? (
              <div className="text-center">No Products Found</div>
            ) : (
              <>
                <div className="row">
                  {getAllProducts?.map((product,index) => (
                    <div className="col-sm-6 col-lg-4" key={index}>
                      <ProductComp
                        id={product.id}
                        title={product.title}
                        discount={product.discount}
                        quantity={product.quantity}
                        sales={product.sales}
                        price={product.salePrice}
                        rating={product.avgRating}
                        thumbnail={product.thumbnail}
                        categoryId={product.subCategoryId}
                      />
                    </div>
                  ))}
                </div>
                <div className="text-center">
                  {getAllProducts?.length === 0 ||
                  magic === false ||
                  getAllProducts?.length < 9 ||
                  localStorage.getItem("page") === "null" ? (
                    ""
                  ) : (
                    <button
                      className="btn btn-dark rounded"
                      onClick={handlePage}
                    >
                      Show More
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Items;
