import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swiper from "swiper";
import "swiper/css";
import ProductComp from "../ProductComp";

function NewArrivals() {
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    const carouselSlider = new Swiper(".product-slider-container", {
      autoplay: false,
      slidesPerView: 4,
      slidesPerGroup: 1,
      spaceBetween: 30,
      speed: 600,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1200: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        576: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        0: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
      on: {
        init: function () {
          setSwiper(this); // Save the Swiper instance in state
        },
      },
    });

    return () => {
      // Clean up the Swiper instance when the component unmounts
      carouselSlider.destroy();
    };
  }, []);

  const goToNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const goToPrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };
  const newArivalData = useSelector(
    (state) => state.newarivalProduct.newArivalProduct
  );

  return (
    <div className="wrapper">
      <section className="product-area product-new-arrivals-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 m-auto">
              <div className="section-title text-center">
                <h2 className="title">New Arrivals</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="swiper-container swiper-slide-gap product-slider-container">
                <div className="swiper-wrapper">
                  {newArivalData?.map((viewdproduct) => (
                    <div className="swiper-slide">
                      <ProductComp
                        id={viewdproduct.id}
                        title={viewdproduct.title}
                        discount={viewdproduct.discount}
                        quantity={viewdproduct.quantity}
                        sales={viewdproduct.sales}
                        price={viewdproduct.salePrice}
                        rating={viewdproduct.avgRating}
                        thumbnail={viewdproduct.thumbnail}
                      />
                    </div>
                  ))}
                </div>
                <div className="swiper-button-prev" onClick={goToPrev}>
                  <i className="ei ei-icon_arrow_carrot-left"></i>
                </div>
                <div className="swiper-button-next" onClick={goToNext}>
                  <i className="ei ei-icon_arrow_carrot-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default NewArrivals;
