import React, { useEffect, useState } from "react";
import moment from "moment";

function UserReview(props) {
  useEffect(() => {
    getReview();
  });

  const [reviewData, setReviewData] = useState([]);

  const getReview = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/review/getAll?productId=${props.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setReviewData(result.data);
      })
      .catch((error) => console.log("Error:", error));
  };

  // Define the formatDate function here
  const formatDate = (createdAt) => {
    return moment(createdAt).format("MMMM D, YYYY");
  };

  return (
    <div className="reviews-content-body">
      {reviewData.map((review, index) => (
        <div key={index} className="review-item">
          {Array.from({ length: 5 }).map((_, index) => (
            <i
              key={index}
              className={
                index < review.rating ? "fa fa-star" : "fa fa-star-o"
              }
            ></i>
          ))}

          <h5 className="sub-title">
            <span>{review.username}</span> -{" "}
            <span>{formatDate(review.createdAt)}</span>
          </h5>
          <p>{review.review}</p>
        </div>
      ))}
    </div>
  );
}

export default UserReview;
