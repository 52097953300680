import React, { useEffect, useState } from "react";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import Models from "../Components/ProductDetails/Models";
import ProductView from "../Components/ProductDetails/ProductView";
import RelatedProducts from "../Components/ProductDetails/RelatedProducts";
import { Link, useNavigate, useParams } from "react-router-dom";

function ProductDetails() {
  const params = useParams();

  const [product, setProduct] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [params]);

  useEffect(() => {
    getProductDetails();
  }, [params]);

  const getProductDetails = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    await fetch(
      `${process.env.REACT_APP_API_URL}user/product/getOne?id=${params.id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === 'error') {
          navigate("/error");
        } else {
          setProduct(result.data);
        }
        console.log(result)
      })
      .catch((error) => console.log("Error:", error));
  };

  return (
    <div>
      <Navigation />
      <div className="wrapper">
        <main className="main-content">
          <div className="page-header-area">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center">
                  <div className="page-header-content">
                    <nav className="breadcrumb-area">
                      <ul className="breadcrumb">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li className="breadcrumb-sep">/</li>
                        <li>Product Details</li>
                      </ul>
                    </nav>
                    <h4 className="title">Product Details</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="product-area product-single-area">
            <div className="container pt-60 pb-0">
              <ProductView
                id={product.id}
                title={product.title}
                discount={product.discount}
                quantity={product.quantity}
                sales={product.sales}
                salePrice={product.salePrice}
                avgRating={product.avgRating}
                colors={product.colors}
                description={product.description}
                thumbnail={product.thumbnail}
                images={product.images && JSON.parse(product.images)}
              />
              <Models id={product.id} />
              {product.subCategoryId !== undefined ? (
                <RelatedProducts id={product?.subCategoryId} />
              ) : (
                ""
              )}
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default ProductDetails;
