import React from "react";
import BestSeller from "./BestSeller";
import MostView from "./MostView";

function ProductArea() {
  return (
    <div className="wrapper">
      <section className="product-area">
        <div className="container product-pb" data-padding-bottom="25">
          <div className="row">
            <div className="col-12">
              <div className="product-category-tab-wrap">
                <ul
                  className="nav nav-tabs product-category-nav justify-content-center"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="best-seller-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#bestSeller"
                      type="button"
                      role="tab"
                      aria-controls="bestSeller"
                      aria-selected="true"
                    >
                      Best Seller
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="most-view-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#mostView"
                      type="button"
                      role="tab"
                      aria-controls="mostView"
                      aria-selected="false"
                    >
                      Most View
                    </button>
                  </li>
                </ul>
                <div
                  className="tab-content product-category-content"
                  id="myTabContent"
                >
                  <BestSeller/>
                  <MostView/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ProductArea;
