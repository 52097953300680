import React from "react";
import UserReview from "./UserReview";
import AddReview from "./AddReview";

function Models(props) {
  const { id } = props;
  return (
    <div className="row mt-5">
      <div className="col-12">
        <div className="product-review-tabs-content">
          <ul className="nav product-tab-nav" id="ReviewTab" role="tablist">
            <li role="presentation">
              <a
                id="reviews-tab"
                data-bs-toggle="pill"
                href="#reviews"
                role="tab"
                aria-controls="reviews"
                aria-selected="false"
              >
                Reviews
              </a>
            </li>
            <li role="presentation">
              <a
                id="shipping-policy-tab"
                data-bs-toggle="pill"
                href="#shipping-policy"
                role="tab"
                aria-controls="shipping-policy"
                aria-selected="false"
              >
                Shipping Policy
              </a>
            </li>
          </ul>
          <div
            className="tab-content product-tab-content"
            id="ReviewTabContent"
          >
            <div
              className="tab-pane fade"
              id="reviews"
              role="tabpanel"
              aria-labelledby="reviews-tab"
            >
              <div className="product-review-content">
                <div className="review-content-header">
                  <h3>Customer Reviews </h3>
                </div>
                <UserReview id={id} />
                <AddReview id={id} />
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="shipping-policy"
              role="tabpanel"
              aria-labelledby="shipping-policy-tab"
            >
              <div className="product-shipping-policy">
                <div className="section-title">
                  <h2 className="title">Shipping policy for our store</h2>
                  <p>
                    At Zark Official, we are committed to providing a seamless and
                    efficient shipping experience for our customers in Pakistan.
                    Please take a moment to familiarize yourself with our
                    shipping policy, which includes important details about
                    shipping fees, delivery times, and other essential
                    information.
                  </p>
                </div>
                <ul className="shipping-policy-list">
                  <li>2-4 business days (Typically by end of day)</li>
                  <li>Delivery only Avaliable in Pakistan</li>
                  <li>24/7 live support</li>
                </ul>
                <p>
                  Shipping Fee: For all orders shipped to addresses within
                  Pakistan, a flat shipping fee of PKR 199 will apply. This fee
                  is subject to change, and any updates will be clearly
                  communicated during the checkout process.
                </p>
                <p>
                  Order Processing Time: Once your order is placed and payment
                  is confirmed, it will undergo processing. Typically, orders
                  are processed within business days. Please note that
                  processing times may vary during peak seasons or due to
                  unforeseen circumstances. We will make every effort to keep
                  you informed in such cases.
                </p>
                <p>
                  Customer Support: Our customer support team is here to assist
                  you with any shipping-related inquiries. If you have questions
                  about your order, shipping fees, or any other concerns, please
                  don't hesitate to contact our support team.
                </p>
                <p>
                  Policy Updates: This shipping policy is subject to change. Any
                  updates or modifications will be posted on our website, and
                  the revised policy will apply to all new orders. Thank you for
                  choosing Zark Official for your shopping needs. We
                  appreciate your business and aim to make your online shopping
                  experience as convenient as possible. If you have any further
                  questions or need assistance with your order, please do not
                  hesitate to contact us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Models;
