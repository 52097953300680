import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import Navigation from "../Components/Navigation";
import BreadCrumb from "../Components/BreadCrumb/BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [credentials, setCredentials] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (credentials.name.length === 0) {
      toast("Please enter Your Name");
      return;
    }
    if (credentials.email.length === 0) {
      toast("Please Enter Your Email");
      return;
    }
    if (credentials.phone.length === 0) {
      toast("Please Enter Your Phone");
      return;
    }
    if (credentials.message.length === 0) {
      toast("Please Enter Your Message");
      return;
    }

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("name", credentials.name);
      urlencoded.append("email", credentials.email);
      urlencoded.append("phone", credentials.phone);
      urlencoded.append("message", credentials.message);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_API_URL}user/support/addRecord`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            toast("Message Submitted Successfully");
            setCredentials({
              name: "",
              email: "",
              phone: "",
              message: "",
            });
          } else if (response.status === "error") {
            toast(response.message);
          } else {
            toast("Internal Server Error");
          }
        })
        .catch((error) => {
          console.error(error);
          toast("Internal Server Error");
        });
    } catch (error) {
      console.error(error);
      toast("Internal Server Error");
    }
  };

  return (
    <div className="wrapper">
      <Navigation />
      <BreadCrumb title="Contact" name="Contact" />
      <main className="main-content">
        <section className="contact-info-area">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-6">
                <div className="contact-info-item">
                  <h4>CONTACT DIRECTLY</h4>
                  <p>support@zarkofficial.com</p>
                  <p>+92 304 4380066</p>
                  <p>+92 307 0684819</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6">
                <div className="contact-info-item">
                  <h4>VISIT OUR SHOP</h4>
                  <p>Shop #41/42 main bhowana bazar near polkadot shop</p>
                  <p>Faisalabad 38000</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="contact-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title">
                  <h2>Get in touch with us</h2>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="contact-form-content">
                  <div className="contact-form">
                    <form id="contact-form" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="name"
                              placeholder="Your name"
                              required
                              onChange={onChange}
                              value={credentials.name}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="email"
                              name="email"
                              placeholder="Your email"
                              required
                              onChange={onChange}
                              value={credentials.email}
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="phone"
                              placeholder="Phone Number"
                              onChange={onChange}
                              value={credentials.phone}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea
                              className="form-control"
                              name="message"
                              placeholder="Your Message"
                              onChange={onChange}
                              value={credentials.message}
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group text-center">
                            <button
                              className="btn btn-link btn-contact"
                              type="submit"
                            >
                              Send Message
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="form-message"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
      <ToastContainer />
    </div>
  );
}

export default Contact;
