import React, { useEffect, useState } from "react";
import Swiper from "swiper";
import "swiper/css";
import ProductComp from "../ProductComp";

function RelatedProducts(props) {
  const [swiper, setSwiper] = useState(null);
  const [related, setRelated] = useState([]);
  console.log(related)

  useEffect(() => {
    const carouselSlider = new Swiper(".single-product-slider-container", {
      autoplay: false,
      slidesPerView: 4,
      slidesPerGroup: 1,
      spaceBetween: 30,
      speed: 600,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        1200: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        576: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        0: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
      on: {
        init: function () {
          setSwiper(this); // Save the Swiper instance in state
        },
      },
    });

    return () => {
      // Clean up the Swiper instance when the component unmounts
      carouselSlider.destroy();
    };
  }, []);

  const goToNext = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };

  const goToPrev = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };
  useEffect(() => {
    getRelatedProducts();
  }, []);

  const getRelatedProducts = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(
      `${process.env.REACT_APP_API_URL}user/product/getAll?subCategoryId=${props.id}`
    )
      .then((response) => response.json())
      .then((result) => {
        setRelated(result.data);
      })
      .catch((error) => console.log("Error:", error));
  };

  return (
    <section className="product-area product-new-arrivals-area product-related-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 m-auto">
            <div className="section-title text-center">
              <h2 className="title">Related Products</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="swiper-container swiper-slide-gap single-product-slider-container">
              <div className="swiper-wrapper">
                {related.map((viewedProduct) => (
                  <div className="swiper-slide" key={viewedProduct.id}>
                    <ProductComp
                    id={viewedProduct.id}
                      title={viewedProduct.title}
                      discount={viewedProduct.discount}
                      quantity={viewedProduct.quantity}
                      sales={viewedProduct.sales}
                      price={viewedProduct.salePrice}
                      rating={viewedProduct.avgRating}
                      thumbnail={viewedProduct.thumbnail}
                    />
                  </div>
                ))}
              </div>
              <div className="swiper-button-prev" onClick={goToPrev}>
                <i className="ei ei-icon_arrow_carrot-left"></i>
              </div>
              <div className="swiper-button-next" onClick={goToNext}>
                <i className="ei ei-icon_arrow_carrot-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RelatedProducts;
