import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../Components/Footer";
import Navigation from "../Components/Navigation";
import { setCart } from "../redux/actions/cart";

function Cart() {
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cartList.cart);

  const handleDeleteCartItem = (itemId) => {
    const updatedCartData = cartData.filter((item) => item.id !== itemId);
    dispatch(setCart(updatedCartData));
  };
  const handleClearCart = () => {
    dispatch(setCart([]));
  };
  let totalCharges = 0;

  return (
    <div>
      <Navigation />
      <main className="main-content">
        <div className="page-header-area">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <div className="page-header-content">
                  <h4 className="title mt-0">Your Shopping Cart</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="product-area shopping-cart-area">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="shopping-cart-wrap">
                  {cartData.length === 0 ? (
                    <p className="cart-empty text-center bg-light p-3">
                      <span className="text-muted fs-5">Cart is empty</span>
                    </p>
                  ) : (
                    <>
                      <div className="cart-table table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="indecor-product-remove">Remove</th>
                              <th className="indecor-product-thumbnail">
                                Image
                              </th>
                              <th className="indecor-product-name">Product</th>
                              <th className="indecor-product-price">Price</th>
                              <th className="indecor-product-quantity">
                                Quantity
                              </th>
                              <th className="indecor-product-subtotal">
                                Total
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {cartData.map((item, index) => (
                              <tr key={index}>
                                <td className="indecor-product-remove">
                                  <button
                                    className="cart-trash btn"
                                    onClick={() =>
                                      handleDeleteCartItem(item.id)
                                    }
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                                <td className="indecor-product-thumbnail">
                                  <img
                                    src={`https://admin.zarkofficial.com/products/${item.thumbnail}`}
                                    alt={item.title}
                                  />
                                </td>
                                <td className="indecor-product-name">
                                  <h4 className="title">{item.title}</h4>
                                </td>
                                <td className="indecor-product-price">
                                  <span className="price">
                                    Rs.{item.price.toFixed(2)}
                                  </span>
                                </td>
                                <td className="indecor-product-quantity">
                                  <span className="price">{item.quantity}</span>
                                </td>
                                <td className="product-subtotal">
                                  {}
                                  <span className="price">
                                    Rs.{totalCharges = item.price * item.quantity.toFixed(2)}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-lg-7 col-12">
                          <div className="coupon-all">
                            <div className="coupon">
                              <Link className="button rounded" to="/products">
                                Continue Shopping
                              </Link>
                              <button
                                className="button ms-2 rounded"
                                onClick={handleClearCart}
                              >
                                Clear Cart
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-5 col-12">
                          <div className="cart-page-total">
                            <h3>Cart Totals</h3>
                            <ul>
                              <li>
                                Subtotal{" "}
                                <span className="money">Rs. {totalCharges}</span>
                              </li>
                              <li>
                                Shipping Charges{" "}
                                <span className="money">199</span>
                              </li>
                              <li>
                                Total{" "}
                                <span className="money">
                                  Rs. {totalCharges+199}
                                </span>
                              </li>
                            </ul>
                            <Link
                              className="proceed-to-checkout-btn rounded"
                              to="/checkout"
                            >
                              Proceed to Checkout
                            </Link>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Cart;
