import React, { useEffect } from "react";
import Navigation from "../Components/Navigation";
import Footer from "../Components/Footer";
import HeroSection from "../Components/Hero/HeroSection";
import AreaWrapper from "../Components/Area/AreaWrapper";
import ProductArea from "../Components/Product/ProductArea";
import SingleBanner from "../Components/Banner/SingleBanner";
import NewArrivals from "../Components/Product/NewArrivals";
import PreLoader from "../Components/Loader/PreLoader";
import { useDispatch } from "react-redux";
import { setBestSeller } from "../redux/actions/bestSeller";
import { setMostView } from "../redux/actions/MostView";
import { setNewArivalProduct } from "../redux/actions/NewArivalProduct";
import { setAllCategories } from "../redux/actions/allcategories";
import { setTopRated } from "../redux/actions/toprated";

function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch bestSeller
    getBestSeller();
    // Dispatch mostview
    getMostView();
    // Dispatch newArrival
    getNewArival();
    // categories dispatch
    getAllCategories();
    // top rated dispatch
    getTopRated();

    window.scrollTo(0, 0);
  }, []);

  const getBestSeller = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/product/bestseller`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setBestSeller(result.data));
      })
      .catch((error) => console.log("Error:", error));
  };

  const getMostView = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/product/mostViewed`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setMostView(result.data));
      })
      .catch((error) => console.log("Error:", error));
  };

  const getNewArival = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/product/newArrival`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setNewArivalProduct(result.data));
      })
      .catch((error) => console.log("Error:", error));
  };

  const getAllCategories = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/category/getAll`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setAllCategories(result.data));
      })
      .catch((error) => console.log("Error:", error));
  };

  const getTopRated = () => {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_API_URL}user/product/topRated`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        dispatch(setTopRated(result.data));
      })
      .catch((error) => console.log("Error:", error));
  };

  return (
    <div>
      {/* <PreLoader /> */}
      <Navigation />
      <HeroSection />
      <AreaWrapper />
      <ProductArea />
      {/* <SingleBanner /> */}
      <NewArrivals />
      <Footer />
    </div>
  );
}

export default Home;
